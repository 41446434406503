import "./work.scss";
type WorkCardProps = {
  title: string;
  company: string;
  location: string;
  when: string;
};

const WorkCard = ({ title, company, location, when }: WorkCardProps) => {
  return (
    <div className="work-card">
      <div className="header">{title}</div>
      <div className="subheader">{company}</div>
      <div className="text">{location}</div>
      <div className="text">{when}</div>
    </div>
  );
};
export default WorkCard;
