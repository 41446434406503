import Screen from "../../components/Screen";
import "./About.scss";
const About = () => {
  const aboutParagraph =
    "Joey lives in Kalamazoo, MI with his two dogs and cat.  When he isn't writing code, he enjoys meditating, working out, playing with his pets, building guitars, playing music, reading books, working on his house, and cooking good food";
  return (
    <Screen>
      <div className="about-hero">
        <h1>About</h1>
      </div>
      <div className="about-container">
        <p className="about-text">{aboutParagraph}</p>
      </div>
    </Screen>
  );
};

export default About;
