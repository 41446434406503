import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "./NavBar.scss";

const NavBar = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleMenuItemClick = (path: string) => {
    navigate(path);
    setShowMenu(false);
  };

  const menuDropdown = () => {
    return (
      <div className="dropdown-menu">
        <button
          className="dropdown-menu-item"
          onClick={() => handleMenuItemClick("/software")}
        >
          Software
        </button>
        <button
          className="dropdown-menu-item"
          onClick={() => handleMenuItemClick("/work")}
        >
          Work
        </button>
        <button
          className="dropdown-menu-item"
          onClick={() => handleMenuItemClick("/education")}
        >
          Education
        </button>
        <button
          className="dropdown-menu-item"
          onClick={() => handleMenuItemClick("/about")}
        >
        About
      </button>
        <button
          className="dropdown-menu-item"
          onClick={() => handleMenuItemClick("/contact")}
        >Contact</button>
      </div>
    );
  };

  return (
    <div className="navbar-container">
      <button className="home-nav-button" onClick={() => navigate("/")}>
        Joey Stempky
      </button>
      <div className="navbar-button-container">
        <button className="mobile-burger-button" onClick={handleMenuClick}>
          🍔
        </button>

        <button className="navbar-button" onClick={() => navigate("/software")}>
          Software
        </button>
        <button className="navbar-button" onClick={() => navigate("/work")}>
          Work
        </button>
        <button
          className="navbar-button"
          onClick={() => navigate("/education")}
        >
          Education
        </button>
        <button className="navbar-button" onClick={() => navigate("/about")}>
          About
        </button>
        <button className="navbar-button" onClick={() => navigate("/contact")}>
          Contact
        </button>
        {showMenu && menuDropdown()}
      </div>
    </div>
  );
};

export default NavBar;
