import { useNavigate } from "react-router-dom";
import "./Home.scss";

const HomeScreenButton = ({ title, path }: { title: string; path: string }) => {
  const navigate = useNavigate();
  return (
    <div className="home-button" onClick={() => navigate(path)}>
      {title}
    </div>
  );
};

export default HomeScreenButton;
