import "./Education.scss";
import wmuLogo from "../../assets/studying.webp";
import Screen from "../../components/Screen";

function Education() {
  return (
    <Screen>
      <div className="education-container">
        <div className="hero">
          <h1>Education</h1>
        </div>
        <div className="educations">
          <div className="education">
            <h2>Western Michigan University</h2>
            <h3>B.S.E - Industrial & Entrepreneurial Engineering</h3>
            <h4>Minors in Mathematics & Biomedical Sciences</h4>

            <p><i>2007 - 2014</i></p>
            <div className="activities-container">
              <h3>Activities</h3>
              <ul>
                <li>Alternative Spring Break</li>
                <li>Resident Assistant</li>
                <li>National Medical Quiz Bowl Competitor</li>
              </ul>
            </div>
          </div>
          <div className="education">
            <h2>Loy Norrix High School</h2>
            <h3>High School Diploma</h3>
            <p><i>2003 - 2007</i></p>
            <div className="activities-container">
              <h3>Activities</h3>
              <ul>
                <li>Basketball (4 years); Captain</li>
                <li>Football (4 years); Captain</li>
                <li>Lacrosse (1 year)</li>
                <li>National Honor Society; Secretary</li>
                <li>National Medical Quiz Bowl Competitor</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Screen>
  );
}

export default Education;
