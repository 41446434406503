import Screen from "../../components/Screen";
import "./work.scss";
import WorkCard from "./WorkCard";
import { workConfig } from "./workConfig";

const Work = () => {
  const { workData, title, subtitle } = workConfig;

  return (
    <Screen>
      <div className="work">
        <div className="work-header">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>
        <div className="work-card-container">
          {workData.map((work, index) => (
            <WorkCard
              key={index}
              title={work.title}
              company={work.company}
              location={work.location}
              when={work.when}
            />
          ))}
        </div>
      </div>
    </Screen>
  );
};

export default Work;
