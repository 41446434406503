import Screen from "../components/Screen";
const Contact = () => {
  const email = "joey.stempky@gmail.com";
  return (
    <Screen>
      <h1>Contact</h1>
      <div
        style={{
          backgroundColor: "#fff",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          borderRadius: 150,
          padding: 30,
          fontSize: "1.5em",
        }}
      >
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </Screen>
  );
};

export default Contact;
