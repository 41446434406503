export const SoftwareConfig = {
  title: "*Current* Technical Skills & Tools",
  subtitle:
    "There are others I am proficient in, but I don't care to advertise them here in fear of having to work with them daily.",
  category: [
    {
      header: "Front-End",
      tools: ["React/React Native (with TypeScript)"],
    },
    {
      header: "Back-End",
      tools: ["Python (Flask)", "Go", "C/C++"],
    },
    {
      header: "Database",
      tools: ["PostgreSQL"],
    },
    {
      header: "Cloud Provider",
      tools: ["GCP (ACE Certified)"],
    },
    {
      header: "Editor",
      tools: ["Neovim", "tmux"],
    },
    {
      header: "OS",
      tools: ["MacOS", "Linux"],
    },
  ],
};
