import { SoftwareConfig } from "./SoftwareConfig";
import "./Software.scss";

const Skills = () => {
  const { title, subtitle, category } = SoftwareConfig;
  return (
    <>
      <div className="skills">
        <div className="hero">
          <div className="hero-overlay">
            <h1>{title}</h1>
            <p>{subtitle}</p>
          </div>
        </div>
        <div className="skills-container">
          {category.map((skill) => (
            <>
              <h2>{skill.header}</h2>
              {skill.tools.map((tool) => (
                <p>{tool}</p>
              ))}
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default Skills;
