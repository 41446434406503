export const workConfig = {
  title: "Work",
  subtitle: "For details on my work experience, please contact me directly.",
  workData: [
    {
      title: "Software Engineer",
      company: "Inspire11",
      location: "Chicago, IL - Remote",
      when: "2024 - Present",
    },
    {
      title: "Software Engineer",
      company: "Integral.io",
      location: "Royal Oak, MI - Remote",
      when: "2022 - 2024",
    },
    {
      title: "Director of Manufacturing",
      company: "Nextek Power Systems",
      location: "Detroit, MI",
      when: "2018 - 2022",
    },
    {
      title: "Manufacturing Engineering Manager",
      company: "Nextek Power Systems",
      location: "Detroit, MI",
      when: "2015 - 2018",
    },
    {
      title: "Controls Specialist/Engineer",
      company: "Nextek Power Systems",
      location: "Detroit, MI",
      when: "2014 - 2015",
    },
    {
      title: "Research and Development Engineering Intern",
      company: "Special-Lite, Inc.",
      location: "Decatur, MI",
      when: "2014",
    },
    {
      title: "Sales and Marketing Intern",
      company: "Custer Workplace Interiors",
      location: "Grand Rapids, MI",
      when: "2013 - 2014",
    },
    {
      title: "Cost Management Engineering Intern",
      company: "Eaton Corporation",
      location: "Galesburg, MI",
      when: "2010 - 2013",
    },
  ],
};
